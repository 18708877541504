import { throwError, Observable, ReplaySubject } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";


@Injectable()
export class BranchService {
  branchSelected: ReplaySubject<any> = new ReplaySubject(1);
  branchSelectedCompanyID: ReplaySubject<any> = new ReplaySubject(1);
  private orderUrl = `${environment.orderApi}`;
  // public sm_company_id;
  constructor(private router: Router, private http: HttpClient) {
  }

  getAllBranches(page: number, limit: number): Observable<any> {
    const query = `?page=${page}&limit=${limit}`;
    return this.http.get(`${this.orderUrl}branch${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }
  configureRequiredFields(reqFields): Observable<any> {
    return this.http.patch(`${this.orderUrl}branch/update-required`, reqFields)
    .pipe(catchError((error: any) => throwError(error)));
  }
  getBranchById(branch_id: string): Observable<any> {
    return this.http.get(`${this.orderUrl}branch/${branch_id}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  set companyID(companyID: string) {
    sessionStorage.setItem('compID', companyID);
  }
  get companyID(): string {
    return sessionStorage.getItem('compID');
  }
}
