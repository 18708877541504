<div class="grid-wrapper-terms">
  <div class="wrapper-item wrapper-item-side flex flex-dir-col align-flex-center ">
    <h3 class="text-36 gray-text">Terms &<br> Conditions</h3>
  </div>

  <div class="wrapper-item wrapper-item-main">
    <h1 class="text-20">Welcome to Ordering Portal!</h1>
    <p class="text-14">
      These terms and conditions outline the rules and regulations for the use of Company Name's Website, located at
      Website.com.
      <br><br>
      By accessing this website we assume you accept these terms and conditions. Do not continue to use Website Name if
      you do not agree to take all of the terms and conditions stated on this page.
      <br><br>
      The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all
      Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the
      Company's terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
      “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance and
      consideration of payment necessary to undertake the process of our assistance to the Client in the most
      appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's
      stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above
      terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as
      interchangeable and therefore as referring to same.
    </p>

    <p class="text-16">Cookies</p>

    <p class="text-14">
      We employ the use of cookies. By accessing Website Name, you agreed to use cookies in agreement with the Company
      Name's Privacy Policy.
      <br>
      Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by
      our website to enable the functionality of certain areas to make it easier for people visiting our website. Some
      of our affiliate/advertising partners may also use cookies.
    </p>

    <p class="text-16">License</p>

    <p class="text-14">
      Unless otherwise stated, Company Name and/or its licensors own the intellectual property rights for all material
      on Website Name. All intellectual property rights are reserved. You may access this from Website Name for your own
      personal use subjected to restrictions set in these terms and conditions.
      <br>
      You must not:
      Republish material from Website Name
      Sell, rent or sub-license material from Website Name
      Reproduce, duplicate or copy material from Website Name
      Redistribute content from Website Name
      <br>
      This Agreement shall begin on the date hereof.
      <br>
      Parts of this website offer an opportunity for users to post and exchange opinions and information in certain
      areas of the website. Company Name does not filter, edit, publish or review Comments prior to their presence on
      the website. Comments do not reflect the views and opinions of Company Name,its agents and/or affiliates. Comments
      reflect the views and opinions of the person who post their views and opinions. To the extent permitted by
      applicable laws, Company Name shall not be liable for the Comments or for any liability, damages or expenses
      caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this
      website.
      <br>
      Company Name reserves the right to monitor all Comments and to remove any Comments which can be considered
      inappropriate, offensive or causes breach of these Terms and Conditions.
      <br>
      You warrant and represent that:
      You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
      The Comments do not invade any intellectual property right, including without limitation copyright, patent or
      trademark of any third party;
      The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is
      an invasion of privacy
      The Comments will not be used to solicit or promote business or custom or present commercial activities or
      unlawful activity.
      <br>
      You hereby grant Company Name a non-exclusive license to use, reproduce, edit and authorize others to use,
      reproduce and edit any of your Comments in any and all forms, formats or media.
    </p>

    <p class="text-16">Hyperlinking to our Content</p>

    <p class="text-14">
      The following organizations may link to our Website without prior written approval:
      Government agencies;
      Search engines;
      News organizations;
      Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of
      other listed businesses; and
      System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity
      fundraising groups which may not hyperlink to our Web site.
      <br>

      These organizations may link to our home page, to publications or to other Website information so long as the
      link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the
      linking party and its products and/or services; and (c) fits within the context of the linking party's site.
      <br>
      We may consider and approve other link requests from the following types of organizations:
      commonly-known consumer and/or business information sources;
      dot.com community sites;
      associations or other groups representing charities;
      online directory distributors;
      internet portals;
      accounting, law and consulting firms; and
      educational institutions and trade associations.
      <br>
      We will approve link requests from these organizations if we decide that: (a) the link would not make us look
      unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records
      with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Company Name; and
      (d) the link is in the context of general resource information.
      <br>
      These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not
      falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits
      within the context of the linking party's site.
      <br>
      If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you
      must inform us by sending an e-mail to Company Name. Please include your name, your organization name, contact
      information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and
      a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
      <br>
      Approved organizations may hyperlink to our Website as follows:
      By use of our corporate name; or
      By use of the uniform resource locator being linked to; or
      By use of any other description of our Website being linked to that makes sense within the context and format of
      content on the linking party's site.our Comments in any and all forms, formats or media.
    </p>

    <p class="text-16">Content Liability</p>

    <p class="text-14">
      We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us
      against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted
      as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other
      violation of, any third party rights.
    </p>
    <p class="text-16">Reservation of Rights</p>

    <p class="text-14">
      We reserve the right to request that you remove all links or any particular link to our Website. You approve to
      immediately remove all links to our Website upon request. We also reserve the right to amen these terms and
      conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to
      and follow these linking terms and conditions.
    </p>

  </div>
  <!-- <div class="wrapper-item wrapper-item-bottom">
  </div> -->

</div>
