import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-branch-action-modal',
  templateUrl: './branch-action-modal.component.html',
  styleUrls: ['./branch-action-modal.component.css']
})
export class BranchActionModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BranchActionModalComponent>,
  ) { }

  ngOnInit(): void {
  }
  confirm() {
    this.dialogRef.close(true);
  }
}
