import { throwError, Observable} from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class UserService {

  private orderUrl = `${environment.orderApi}`;
  private gateway  = `${environment.authMS}`;

  // public sm_company_id;
  constructor(private router: Router, private http: HttpClient) {}


  getCurrentUser(): Observable<any> {
    return this.http
      .get(`${this.orderUrl}user/current`)
      .pipe(catchError((error: any) => throwError(error)));
  }


//! Salje se company_id
  getNewOrderMenager(): Observable<any> {
    return this.http
      .get(`${this.orderUrl}user/available-users-lissa`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  //! order_branch_id = []; ide na gateway i mail od orderMenagera
  createNewMenager(menager_email:string, order_branch_id): Observable<any> {
    return this.http
      .patch(`${this.gateway}auth/user/${menager_email}`, {order_branch_id, append_roles: 'order-client-manager'})
      .pipe(catchError((error: any) => throwError(error)));
  }

  //! Salje se company_id
  getCompanyBranches(company_id:number): Observable<any> {
    return this.http
      .get(`${this.orderUrl}branch`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  //! Salje se company_id
  getOrderMenagers(): Observable<any> {
    return this.http
      .get(`${this.orderUrl}user/order-managers`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  //! BRISANJE ORDER MENAGERA
  removeOrderMenager(menager_email:string): Observable<any> {
    return this.http
      .patch(`${this.gateway}auth/user/${menager_email}`, {remove_roles: 'order-client-manager'})
      .pipe(catchError((error: any) => throwError(error)));
  }

   //! UPDAETOVANJE ORDER MENAGERA
   updateOrderMenager(menager_email:string, order_branch_id: any): Observable<any> {
    return this.http
      .patch(`${this.gateway}auth/user/${menager_email}`, {order_branch_id})
      .pipe(catchError((error: any) => throwError(error)));
  }

  hasPermission(user_roles: string[]) {
    return user_roles.includes('order-client-admin') || user_roles.includes('order-admin');
  }

}
