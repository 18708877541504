import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'lissa-order-portal';

  constructor() { }

  ngOnInit(): void {
    let res = new URL(window.location.href);
    let user = res.searchParams.getAll('user')[0];

    if (user) {
      let loggedUser = JSON.parse(user);
      localStorage.setItem("current_user", JSON.stringify(loggedUser));
      }
  
  }
}
