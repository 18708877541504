import { Component, OnInit } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
// import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { BranchModel, CartModul } from '../shared/models';
// import { CartService } from '../shared/services/cart.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
})
export class PortalComponent  {
  // cartProducts: CartModul[] = [];
  // branchList: BranchModel[] = [];

  constructor(
    // private cartService: CartService,
    // public dialog: MatDialog,
    // private router: Router,
    // private toastr: ToastrService,
  ) {}


  ngOnInit(){
    // this.getListOfProductsInChart();

  }


  // getListOfProductsInChart() {
  //   this.cartService.getCart().subscribe(cartRes => {
  //     if (cartRes) {
  //       this.cartProducts = cartRes.items;
  //       console.log('Productiiii', this.cartProducts);
  //     }
  //   });
  // }

  // cartPreview() {
  //   // this.cartService.cartItems.push(this.cartProducts);
  //   if (this.cartProducts.length > 0) {
  //     this.router.navigate(['cart'])
  //     console.log('caoooooooo');
  //   } else {
  //     this.toastr.warning('There are no products in cart!')
  //   }
  // }

}
