
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';



@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {
  public loginForm: FormGroup;

  constructor() { }

  ngOnInit(): void {

  }

}
