<div class="navigation-grid-footer bck-white parent-height-90 bottom-fixed">
  <div class="left-nav">
    <div class="pointer">
      <h5 class="primary-dark-text"  (click)="openInNewWindowTab()">Terms & Condition</h5>
    </div>
  </div>
  <div class="center-nav">
      <img class="pointer" src="../../../../assets/img/1-navigation/ordering-footer-logo.svg" [routerLink]="['/home']" alt="orderin-logo">
  </div>
  <div class="right-nav">
    <div class="pointer">
        <!-- <h5 class="primary-dark-text">Copyright © 2021 Lissa</h5> -->
        <a class="text-14 primary-dark-text footer-ancor" href="https://www.cimet.rs/" target="_blank">Copyright © 2021 Lissa</a>
    </div>
  </div>
</div>

