import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CartService {
  private orderUrl = `${environment.orderApi}user/cart`;
  private orderUrlSingl = `${environment.orderApi}`;
  public confirmBtn: EventEmitter<any> = new EventEmitter();
  public cartItems: any[] = [];
  orders;
  ordersChanged = new BehaviorSubject<number>(0);


  order() {
    this.ordersChanged.next(this.orders);
  }

  provideBulkOrSingle(singleOrBulk: string) {
    sessionStorage.setItem('singleOrBulk', singleOrBulk)
  }

  getSingleOrBulk(){
    return sessionStorage.getItem('singleOrBulk');
  }

  public qty$ = new BehaviorSubject<any>(0);
  public currentQty = this.qty$.asObservable();



  constructor(private router: Router, private http: HttpClient) {}

  addProductToChart(qty: number, product_id: string, additional_products: any, enrollment_profile: string): Observable<any> {
    return this.http
    .post(`${this.orderUrl}/add`, {qty, product_id, additional_products, enrollment_profile })
    .pipe(catchError((error: any) => throwError(error)));
  }


  getCartItems() {
    return this.cartItems;
  }


  emptyCartItems() {
    this.cartItems = [];
  }

  getCart(): Observable<any> {
    return this.http
      .get(`${this.orderUrl}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  removeSingleProductFromCart(product_id: string): Observable<any> {
    return this.http
      .delete(`${this.orderUrl}/item/${product_id}`, {})
      .pipe(catchError((error: any) => throwError(error)));
  }

  changeAmountOfSingleProductFromCart(product_id, payload): Observable<any> {
    Object.keys(payload).forEach((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    return this.http
      .patch(`${this.orderUrl}/item/${product_id}`, payload)
      .pipe(catchError((error: any) => throwError(error)));
  }

  modifyEnrollmentProfileInCart(product_id: string, enrollmentProfile: string,  cartItemId?: string): Observable<any> {
    return this.http
      .patch(`${this.orderUrl}/item/${product_id}`, { id: product_id, enrollment_profile: enrollmentProfile,  cartItemId })
      .pipe(catchError((error: any) => throwError(error)));
  }


  clearCart(): Observable<any> {
    return this.http
    .post(`${this.orderUrl}/clear`, {})
    .pipe(catchError((error: any) => throwError(error)));
  }

  createOrderFromCart(order:{}): Observable<any> {
     return this.http
     .post(`${this.orderUrl}/create-order`, {...order})
     .pipe(catchError((error: any) => throwError(error)));
   }

  getFinishedOrders(page?: number, limit?: number, exportCSV?: boolean, data?: any): Observable<any> {
    const query = `?page=${page}&limit=${limit}`

    let params = new HttpParams();

    if (Object.keys(data).length) {
      Object.keys(data).forEach(k => {
        if (data[k] && typeof data[k] != 'object' && k !== 'device') {
          params = params.set(k, data[k]);
        }
      });
    }
    return this.http
      .get(`${this.orderUrlSingl}order${query}${exportCSV ? '&export=true' : ''}`, {params})
      .pipe(catchError((error: any) => throwError(error)));
  }

}
