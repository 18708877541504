
<div class="modal-content-box">
  <img class="modal-image" src="../../../../assets/img/2-common-img/modal-cart-icon.svg" alt="cart-icon">
    <h3 class="upload-headline-text p-0 text-20 black-text">Your current shopping cart will be discarded.</h3>
    <h3 class="upload-headline-text p-0 text-16 primary-dark-text">Please notice, that you have products in your shopping cart that will be discarded by changing the branch.</h3>
  <div mat-dialog-actions class="mt-3 mb-1 p-3">
    <button mat-button mat-dialog-close class="btn grey-black-btn-modal mr-3">Back</button>
    <button mat-button class="btn blue-white-btn-modal" (click)="confirm()">Change Branch</button>
  </div>
</div>



<!-- <div class="d-flex flex-column justify-content-between align-items-center modal-content-box">
  <div class="mb-5 text-center">
    <h3 class="upload-headline-text p-0 text-28 black-text">You have items in cart! </h3>
    <h3 class="upload-headline-text p-0 text-20 primary-dark-text">Your items will be removed from cart.<br> Are you sure?</h3>
  </div>
  <div class="cart-delete mb-5">
    <img  src="../../../../assets/img/2-common-img/delete-cart.png" alt="">
  </div>
  <div mat-dialog-actions class="mt-3 mb-1 p-3">
    <button mat-button mat-dialog-close class="btn grey-black-btn mr-3">No</button>
    <button mat-button class="btn green-white-btn" (click)="confirm()">Yes</button>
  </div>
</div> -->

