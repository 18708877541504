import { throwError, Observable} from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class ProductsService {

  private orderUrl = `${environment.orderApi}`;
  // public sm_company_id;
  constructor(private router: Router, private http: HttpClient) {}


  getProducts(page?: number, limit?: number): Observable<any> {
    const query = `?page=${page}&limit=${limit}`;
    return this.http
      .get(`${this.orderUrl}product${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  // getProducts(page?: number, limit?: number, categories?:any): Observable<any> {
  //   const query = `?page=${page}&limit=${limit}&categories=${categories}`;
  //   return this.http
  //     .get(`${this.orderUrl}product${query}`)
  //     .pipe(catchError((error: any) => throwError(error)));
  // }


  getProductById(id:number, branch_id: number): Observable<any> {
    // let query = `?branch_id=${branch_id}`
    return this.http.get(`${this.orderUrl}product/${id}?branch_id=${branch_id}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getAllServices(){
    return this.http
    .get(`${this.orderUrl}product/services`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getProducstOfBranch(branchId: number, category_id?: any, type?:string, name?:string):Observable<any> {

    let query =  category_id ? `&category_id=${category_id.map(cat => cat)}` : '';
    if (type && type === 'Service') {
      query = `${query}&type=Service`
    }
    if (name) {
      query = `${query}&name=${name}`
    }
    return this.http.get(`${this.orderUrl}branch/${branchId}/product?page=1&limit=5000${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }


  getProductsForMainPreview(id:number){
    return this.http
    .get(`${this.orderUrl}/branch/${id}/product/preview`)
    .pipe(catchError((error: any) => throwError(error)));
  }







  // treba da se doda filter za pretragu deviceova po kategoriji

}
