
 <div class="container-fluid search-container">
  <div class="row p-0 m-0 search-container-row">
    <div class="col-md-12 p-0">
      <form class="example-form" [formGroup]="searchDevicesForm">
        <mat-form-field  class="custom-form-field"   appearance="fill">
          <!-- <mat-label>Device</mat-label> -->
          <input type="text" matInput placeholder="Search Products" formControlName="name" [matAutocomplete]="auto"/>
          <mat-autocomplete #auto="matAutocomplete" panelClass="search-custom" [displayWith]="devicesAutocompleteView" (optionSelected)="selectedDevice($event?.option?.value)">
            <mat-option class="custom-mat-option my-2" *ngFor="let device of deviceList" [value]="device">
              <img class="example-option-img" aria-hidden [src]="setProductImg(device?.product_img)" height="50">
              <span>{{device?.name}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <img class="pointer float-right mr-3" (click)="closeSearch()" src="../../../../assets/img/1-navigation/close-btn-icon.svg" alt="close-btn">
      </form>
    </div>
  </div>
</div>



