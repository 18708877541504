import { SharedModule } from './shared/shared.module';


import { Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { PortalComponent } from './portal/portal.component';
import { TermsCondtitionsComponent } from './shared/components/terms-condtitions/terms-condtitions.component';
import { AuthGuard } from './shared/guards';



export const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "",
    component: PortalComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./portal/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "products",
        loadChildren: () => import('./portal/products/products.module').then(m => m.ProductsModule),
      },
      {
        path: "cart",
        loadChildren: () => import('./portal/cart/cart.module').then(m => m.CartModule),
      },
      {
        path: "checkout",
        loadChildren: () => import('./portal/cart/cart.module').then(m => m.CartModule),
      },
      {
        path: "account",
        loadChildren: () => import('./portal/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: "terms",
        component: TermsCondtitionsComponent
      }
    ]
  },


  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
    ]
  },
]
