import { CategoryService } from './shared/services/category.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { UserService } from './shared/services/user.service';
import { TermsCondtitionsComponent } from './shared/components/terms-condtitions/terms-condtitions.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { appRoutes } from './app.routes';
import { ToastrModule } from "ngx-toastr";

//MODULES
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
//COMPONENTS

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { PortalComponent } from './portal/portal.component';
import { NavbarComponent } from './shared';
// import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';

// import { AdminComponent } from './portal/admin/admin.component';


//Angular Material
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';



//Authentidifacion
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthenticationService, } from './shared/services';
import { TokenInterceptor } from './shared/interceptors';
import { BranchActionModalComponent } from './shared/modal-dialogs/branch-action-modal/branch-action-modal.component';
import { CartService } from './shared/services/cart.service';
import { BranchService } from './shared/services/branch.service';
import { ProductsService } from './shared/services/products.service';

import { NgxSpinnerModule } from "ngx-spinner";
import { MdmProfileService } from './shared/services/mdmProfile.service';


@NgModule({
  imports: [
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatPaginatorModule
  ],

  exports: [
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatPaginatorModule
  ],

  providers: [],
  bootstrap: [AppComponent]
})
export class MaterialModule {}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MaterialModule,
    HttpClientModule,
    // HttpClient,
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload'
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true,
    }),
    ReactiveFormsModule,
    FormsModule,
    NgxSpinnerModule
  ],



  exports: [
    MaterialModule
  ],

  declarations: [
    AppComponent,
    PortalComponent,
    NavbarComponent,
    FooterComponent,
    AuthComponent,
    BranchActionModalComponent,
    TermsCondtitionsComponent
    // AdminComponent

  ],
  entryComponents: [
    BranchActionModalComponent
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthenticationService,
    BranchService,
    CartService,
    UserService,
    CategoryService,
    ProductsService,
    MdmProfileService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule { }
