import { throwError, Observable} from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class CategoryService {

  private orderUrl = `${environment.orderApi}`;
  // public sm_company_id;
  constructor(private router: Router, private http: HttpClient) {}


  getCategory(): Observable<any> {
    return this.http
      .get(`${this.orderUrl}/category`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCategoryTree(): Observable<any> {
    return this.http
      .get(`${this.orderUrl}/category/tree`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCategoryById(id:string): Observable<any>{
    return this.http.get(`${this.orderUrl}category/${id}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getCategoryByArrayOfId(category_id:any ): Observable<any>{
    let query = category_id ? `?category_id=${category_id.toString()}` : null
    return this.http.get(`${this.orderUrl}category/find-by-id${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }


  // getCategoryById(id:string, category: string): Observable<any>{
  //   const query = `?category=${category}`;
  //   return this.http.get(`${this.orderUrl}category/${id}${query}`)
  //   .pipe(catchError((error: any) => throwError(error)));
  // }

}


