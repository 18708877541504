
export const environment = {
  production: false,
  apiUrl: "http://localhost:8080",
  reApi: "http://localhost:8081",
  remarkApi: "https://api2.lissa-ems-test.com/api/rem/",
  orderApi: "https://api2.lissa-ems-test.com/api/ord/",
  authMS:"https://api2.lissa-ems-test.com/",
  // authMS:"http://localhost:8082/",
  // remarkApi: "http://localhost:8082/api/rem/",
  // orderApi: "http://localhost:8082/api/ord/",
};


