import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class MdmProfileService {
  private orderUrl = `${environment.orderApi}`;

  constructor(private http: HttpClient) {}

  addMdmProfileToBranch(
    branch_id: string,
    mdmProfileName: string
  ): Observable<any> {
    return this.http
      .post(`${this.orderUrl}/mdm-profile`, {
        mdm_profile_name: mdmProfileName,
        branch_id,
      })
      .pipe(catchError((error: any) => throwError(error)));
  }

  deleteMdmProfileFromBranch(
    branch_id: string,
    mdm_profile_Name: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.set("branch_id", branch_id);
    params = params.set("mdm_profile_name", mdm_profile_Name);

    return this.http
      .delete(`${this.orderUrl}/mdm-profile`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }

  getMdmProfiles(branch_id: string): Observable<any> {
    let params = new HttpParams();
    if (branch_id) {
      params = params.set("branch_id", branch_id);
    }

    return this.http
      .get(`${this.orderUrl}/mdm-profile`, { params })
      .pipe(catchError((error: any) => throwError(error)));
  }
}
