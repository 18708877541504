import { Router } from '@angular/router';
import { Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';
import { ProductsService } from '../../services/products.service';
import { BranchService } from '../..';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-search-devices',
  templateUrl: './search-devices.component.html',
  styleUrls: ['./search-devices.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class SearchDevicesComponent implements OnInit {
  serverUrl: string = `${environment.orderApi}`;
  searchDevicesForm: FormGroup;
  public filteredDevices: Subscription;
  branchId: number;
  public deviceList: any[];


  constructor(
    private dialogRef: MatDialogRef<SearchDevicesComponent>,
    private productService: ProductsService,
    private branchService: BranchService,
    private fb: FormBuilder,
    private router: Router
  ) {}

   ngOnInit(): void {
    this.searchDevicesForm = this.fb.group({
      name: [],
    });
    this.branchService.branchSelected.subscribe(branch => {
      if (branch) {
        this.branchId = branch;
        this.initFilters();
      }
    })

  }



  initFilters() {
    this.filteredDevices = this.searchDevicesForm.controls.name.valueChanges
    .pipe(startWith(null))
    .pipe(debounceTime(500))
    .pipe(
      tap(val => {
        if (val && typeof val !== 'object') {
          // this.devices = [];
          this.productService
            .getProducstOfBranch(
            this.branchId, null, null, val
            )
            .subscribe(res => {
              if (res.docs.length !== 0) {
                this.deviceList = res.docs;
              } else {

              }
            });
        } else if (val === '') {
          this.productService.getProducstOfBranch(this.branchId).subscribe(res => this.deviceList = res.docs)
        }
      })
    )
    .subscribe();
  }



  public selectedDevice(device?) {
    this.router.navigate(['products/details/', device?._id])
    this.dialogRef.close(true);
  }


  devicesAutocompleteView(device?: any): string | undefined {
    if (device) {
      return device?.name
    } else {
      return undefined;
    }
  }

  setProductImg(img) {
    if(img) {
      if (img.startsWith('https')) {
        return img;
      }
      if (img.startsWith('public')) {
        return `${environment.orderApi}${img}`;
      }
    } else  {
      return '/assets/img/2-common-img/no_device_placeholder.png';
    }
  }

   closeSearch() {
    this.dialogRef.close(true);
  }



}
